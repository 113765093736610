#footer_navigation {
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  z-index: 10;
  border-top: 1px solid #c1fcfa;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#001a28+0,123647+100 */
  background: rgb(0, 26, 40); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(0, 26, 40, 1) 0%,
    rgba(18, 54, 71, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 26, 40, 1) 0%,
    rgba(18, 54, 71, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 26, 40, 1) 0%,
    rgba(18, 54, 71, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
