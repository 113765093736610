.autorefresh {
  &:before {
    content: "refresh";
    text-transform: uppercase;
    position: relative;
    top: 0.075rem;
  }
}

/* Container styles */

.chart {
  position: relative;
  display: inline-flex;
  display: -webkit-inline-flex;
  color: #999;
  font-size: 20px;
  text-align: center;
  margin: 0 0 0 0.25rem;
  vertical-align: middle;
  margin-top: -2px;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear; /* Safari 4.0 - 8.0 */

  &:after {
    content: "";
    background: transparent;
    top: 3px;
    left: 3px;
    height: 10px;
    width: 10px;
    z-index: 2;
    border: 2px solid rgba(193, 252, 250, 0.25);
    border-radius: 50%;
    position: absolute;
  }

  svg {
    z-index: 3;
  }
}

// Calculate Circle Radius * 2 = Durchmesser || Durchmesser * Pi = stroke-dasharray
$circleRadius: 6;
$strokeDashOffset: ($circleRadius * 2) * 3.1415926536;
$strokeWidth: $circleRadius / 3;
$animationTime: 60s;

.outer {
  fill: transparent;
  stroke: #cc0000;
  stroke-width: $strokeWidth;
  stroke-dasharray: $strokeDashOffset;
  transition: stroke-dashoffset $animationTime;
  /* firefox bug fix - won't rotate at 90deg angles */
  -moz-transform: rotate(-89deg) translateX(-190px);
}

.chart.automatic .outer {
  stroke: greenyellow;
  -webkit-animation: show100 $animationTime infinite;
  animation: show100 $animationTime infinite;
}

/* Keyframes for the initial animation */
@-webkit-keyframes show100 {
  from {
    stroke-dashoffset: $strokeDashOffset;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes show100 {
  from {
    stroke-dashoffset: $strokeDashOffset;
  }

  to {
    stroke-dashoffset: 0;
  }
}

/* END Keyframes for the initial animation */
