html,
body {
  // overflow-x: hidden;
}

body {
  font-family: Arial;
  background: #011824;
  margin: 0;

  * {
    box-sizing: border-box;
  }

  a {
    color: #123647;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.5;
    }
  }

  #root {
    padding-bottom: 64px;
  }

/*   #root {
    padding-top: 80px;
    padding-bottom: 70px;
  } */
}
