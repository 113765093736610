.tipbot {
  margin-top: -35px;
  position: absolute;
  right: 1em;
  top: 50%;

  @media (max-width: 576px) {
    transform: scale(0.5);
    transform-origin: 100% 0.5rem;
    right: 0.25rem;
  }

  &:before {
    content: "Support the developer";
    color: #fff;
    margin-bottom: 0.25rem;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
  }
}
