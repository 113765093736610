h2 {
  padding: 0;
  color: #123647;
  text-transform: uppercase;
  font-size: 1.5em;
  margin-bottom: 1em;
}

h3 {
  color: #123647;
}

p {
  line-height: 1.5em;
  color: #123647;
}

.twitter-float {
  float: right;
}

.styles_overlay__CLSq- {
  -webkit-overflow-scrolling: touch;
}

.styles_closeButton__20ID4 {
  position: absolute;
  top: -12px;
  right: -20px;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  border: 2px solid #123647;
  transition: all 0.2s;

  &:hover {
    .styles_closeIcon__1QwbI {
      fill: #fff;
    }
  }
}

.styles_closeIcon__1QwbI {
  margin: auto;
  fill: #123647;
}

.styles_modal__gNwvD {
  border: 4px solid #123647;
}

.externalPageModal .styles_modal__gNwvD {
  max-width: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: scroll;
  padding: 0;
}

.externalPageModal .styles_modal__gNwvD .iframe-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  top: 52px;
}

.externalPageModal .styles_modal__gNwvD iframe {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  border: 0;
}

.externalPageModal .styles_modal__gNwvD button {
  padding: 0;
}

.externalPageModal .styles_modal__gNwvD button svg {
  fill: #e8fcfb;
}

.externalPageModal .styles_modal__gNwvD .modal-header {
  background: #012423;
  border-radius: 0;
}

.externalPageModal .styles_modal__gNwvD .modal-header img {
  width: 100px;
}

.externalPageModal .styles_modal__gNwvD .modal-header span {
  color: #e8fcfb;
  text-transform: uppercase;
  margin-right: 2rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
}
